import axios, { AxiosRequestConfig, Method } from 'axios'

export class ApiError extends Error {
  code: string | undefined
  description: string | undefined
  constructor(message: string, code?: string, description?: string) {
    super(message)
    this.code = code
    this.description = description
  }
}

export default class ApiService {
  private apiUrl: string | undefined
  private headers: HeadersInit = {
    'Content-Type': 'application/json',
    accept: 'application/json',
  }
  private error: typeof ApiError

  constructor(apiUrl: string, headers?: HeadersInit, error = ApiError) {
    this.apiUrl = apiUrl
    this.headers = { ...this.headers, ...headers }
    this.error = error
  }

  private _retrieveData = async <I, O>(url: string, method: Method = 'get', data?: I): Promise<O> => {
    const requestOptions: AxiosRequestConfig = {
      method,
      url,
      data,
    }
    try {
      const res = await axios(requestOptions)
      if (res.status !== 200) {
        const body = (await res?.data) as unknown as string
        throw new this.error(`ApiError: Error on retrieving data ${res.statusText}`, res.status.toString(), body)
      }
      return res.data as O
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return Promise.resolve(e as O)
      }
      throw e
    }
  }

  public post = <I, O>(path: string, data: I) => {
    const url = `${this.apiUrl}${path}`
    return this._retrieveData<I, O>(url, 'post', data)
  }

  public get = <O>(path: string) => {
    const url = `${this.apiUrl}${path}`
    return this._retrieveData<unknown, O>(url)
  }
}
